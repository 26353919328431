import React, { useState, useEffect, createRef } from "react";

import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as playlistActions from '../../store/playlist/actions';
import * as playindexActions from '../../store/playindex/actions';
import * as isplayingActions from '../../store/isplaying/actions'
import { setPlayIndex } from "../../store/playindex/actions";



const PlayPauseButton = ({ song, playlistItem, parentComponent, addSongVersionToPlayList, playList, setPlayIndex, playIndex, isPlaying, player, playListIndex, version, themeType, listenSong }) => {

    const [firstVersionIdToPlay, setFirstVersionIdToPlay ] = useState();
    const [ currentSongId, setCurrentSongId ] = useState();
    
   
    const checkIfVersionAlreadyExist = (versionId) => {
		var hasMatch = false;
		for (var index = 0; index < playList.length; ++index) {
            var songs = playList[index];
            if(songs.version == versionId){
                hasMatch = index+1;
                break;
            }
        }
        
		return hasMatch;
    }

    
    

    const playPauseSong = async () => {
        
        if(song && song.version){
           
            const currentPlayerSong = {
                name: song.title,
                writer: song.artist,
                // img: song.entity_id + "-" + song.version[0].song_version_id,
                img: song.entity_id,
                src: song.version[0].mp3,
                id: playList.length + 1,
                version: song.version[0].song_version_id,
                entity_id: song.entity_id
            }
            
            if(playList !== undefined && playList.length > 0){
                let songIndex;
                if(songIndex = checkIfVersionAlreadyExist(song.version[0].song_version_id)){
                    // if song found in playlist and based on isPlaying, play or pause the song
                    if(songIndex == playIndex){
                        if(player && player.current && player.current.audio && player.current.audio.current){
                            player.current.isPlaying() ? player.current.audio.current.pause() : player.current.audio.current.play();
                        }
                    } else {
                        setPlayIndex(songIndex);
                    }   
                    // console.log('found', songIndex, playIndex);
                } else {
                    // console.log('not', playList.length);
                    songIndex = playList.length + 1;
                    setPlayIndex(songIndex);
                    await addSongVersionToPlayList(currentPlayerSong);
                }
            } else {
                // console.log('else')
                setPlayIndex(1)
                await addSongVersionToPlayList(currentPlayerSong);
            }
        }
        
    }

    const playPauseSongFromPlaylist = (item) => {
        // dispatch a event to player to playsong by index
        if(item.id == playIndex){
            if(player && player.current && player.current.audio && player.current.audio.current){
                player.current.isPlaying() ? player.current.audio.current.pause() : player.current.audio.current.play();
            }
        } else {
            setPlayIndex(item.id);
        }
    }

    const watchOnPlayPause = () => {
        playPauseSong();
        listenSong(song);
    }

    const watchPlayPauseFromVersion = () => {
        playPauseFromVersion();
        listenSong(song);
    }

    const playPauseFromVersion = async () => {
        if(song && version){
            
            const currentPlayerSong = {
                name: song.title,
                writer: song.artist,
                // img: song.entity_id + "-" + song.version[0].song_version_id,
                img: song.entity_id,
                src: version.mp3,
                id: playList.length + 1,
                version: version.song_version_id,
                entity_id: song.entity_id
            }
            
            if( playList !== undefined && playList.length > 0){
                let songIndex;
                if(songIndex = checkIfVersionAlreadyExist(version.song_version_id)){
                    // if song found in playlist and based on isPlaying, play or pause the song
                    if(songIndex == playIndex){
                        if(player && player.current && player.current.audio && player.current.audio.current){
                            player.current.isPlaying() ? player.current.audio.current.pause() : player.current.audio.current.play();
                        }
                    } else {
                        setPlayIndex(songIndex);
                    }   
                    // console.log('found', songIndex, playIndex);
                } else {
                    // console.log('not', playList.length);
                    songIndex = playList.length + 1;
                    setPlayIndex(songIndex);
                    await addSongVersionToPlayList(currentPlayerSong);
                }
            } else {
                // console.log('else')
                setPlayIndex(1)
                await addSongVersionToPlayList(currentPlayerSong);
            }
        }
    }

    if(parentComponent == 'songTrack'){
        if(playIndex && playList && playList.length>0 && playList[playIndex-1].version &&  song && playList[playIndex-1].img == song.entity_id){
            return (
                <span onClick={() => playPauseSong()} className="cp">
                    
                    
                        {
                            isPlaying ? 
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.1429 15C11.75 15 11.4138 14.8602 11.1343 14.5807C10.8543 14.3007 10.7143 13.9643 10.7143 13.5714V6.42857C10.7143 6.03571 10.8543 5.69952 11.1343 5.42C11.4138 5.14 11.75 5 12.1429 5H13.5714C13.9643 5 14.3007 5.14 14.5807 5.42C14.8602 5.69952 15 6.03571 15 6.42857V13.5714C15 13.9643 14.8602 14.3007 14.5807 14.5807C14.3007 14.8602 13.9643 15 13.5714 15H12.1429ZM6.42857 15C6.03571 15 5.69952 14.8602 5.42 14.5807C5.14 14.3007 5 13.9643 5 13.5714V6.42857C5 6.03571 5.14 5.69952 5.42 5.42C5.69952 5.14 6.03571 5 6.42857 5H7.85714C8.25 5 8.58643 5.14 8.86643 5.42C9.14595 5.69952 9.28571 6.03571 9.28571 6.42857V13.5714C9.28571 13.9643 9.14595 14.3007 8.86643 14.5807C8.58643 14.8602 8.25 15 7.85714 15H6.42857Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"}/>
                            </svg>
                            :
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.97168 11.3912C0.974962 11.5817 1.03039 11.7676 1.13194 11.9288C1.23349 12.09 1.37728 12.2203 1.54768 12.3055C1.71784 12.4017 1.90995 12.4522 2.10539 12.4522C2.30083 12.4522 2.49295 12.4017 2.66311 12.3055L10.5717 7.41408C10.7438 7.33118 10.8889 7.2014 10.9906 7.03966C11.0922 6.87793 11.1461 6.6908 11.1461 6.49979C11.1461 6.30879 11.0922 6.12166 10.9906 5.95992C10.8889 5.79819 10.7438 5.66841 10.5717 5.58551L2.66311 0.730651C2.49295 0.634508 2.30083 0.583984 2.10539 0.583984C1.90995 0.583984 1.71784 0.634508 1.54768 0.730651C1.37728 0.815852 1.23349 0.946127 1.13194 1.10732C1.03039 1.26851 0.974962 1.45445 0.97168 1.64494V11.3912Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"} />
                            </svg>
                            
                        }
                        
                </span>
            )
        } else {
            return (
                <span onClick={() => watchOnPlayPause()} className="cp">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.97168 11.3912C0.974962 11.5817 1.03039 11.7676 1.13194 11.9288C1.23349 12.09 1.37728 12.2203 1.54768 12.3055C1.71784 12.4017 1.90995 12.4522 2.10539 12.4522C2.30083 12.4522 2.49295 12.4017 2.66311 12.3055L10.5717 7.41408C10.7438 7.33118 10.8889 7.2014 10.9906 7.03966C11.0922 6.87793 11.1461 6.6908 11.1461 6.49979C11.1461 6.30879 11.0922 6.12166 10.9906 5.95992C10.8889 5.79819 10.7438 5.66841 10.5717 5.58551L2.66311 0.730651C2.49295 0.634508 2.30083 0.583984 2.10539 0.583984C1.90995 0.583984 1.71784 0.634508 1.54768 0.730651C1.37728 0.815852 1.23349 0.946127 1.13194 1.10732C1.03039 1.26851 0.974962 1.45445 0.97168 1.64494V11.3912Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"} />
                    </svg>
                </span>
            )
        }
    } 
    
    else if(parentComponent == 'playlist'){
        return (
            <span onClick={() => playPauseSongFromPlaylist(playlistItem)} className="cp">
                
                    {
                        isPlaying && playListIndex == playIndex ? 
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1429 15C11.75 15 11.4138 14.8602 11.1343 14.5807C10.8543 14.3007 10.7143 13.9643 10.7143 13.5714V6.42857C10.7143 6.03571 10.8543 5.69952 11.1343 5.42C11.4138 5.14 11.75 5 12.1429 5H13.5714C13.9643 5 14.3007 5.14 14.5807 5.42C14.8602 5.69952 15 6.03571 15 6.42857V13.5714C15 13.9643 14.8602 14.3007 14.5807 14.5807C14.3007 14.8602 13.9643 15 13.5714 15H12.1429ZM6.42857 15C6.03571 15 5.69952 14.8602 5.42 14.5807C5.14 14.3007 5 13.9643 5 13.5714V6.42857C5 6.03571 5.14 5.69952 5.42 5.42C5.69952 5.14 6.03571 5 6.42857 5H7.85714C8.25 5 8.58643 5.14 8.86643 5.42C9.14595 5.69952 9.28571 6.03571 9.28571 6.42857V13.5714C9.28571 13.9643 9.14595 14.3007 8.86643 14.5807C8.58643 14.8602 8.25 15 7.85714 15H6.42857Z" fill={themeType == 'dark-theme' ? "#FFF": '#828282'}/>
                        </svg>
                        // <path d="M12.1429 15C11.75 15 11.4138 14.8602 11.1343 14.5807C10.8543 14.3007 10.7143 13.9643 10.7143 13.5714V6.42857C10.7143 6.03571 10.8543 5.69952 11.1343 5.42C11.4138 5.14 11.75 5 12.1429 5H13.5714C13.9643 5 14.3007 5.14 14.5807 5.42C14.8602 5.69952 15 6.03571 15 6.42857V13.5714C15 13.9643 14.8602 14.3007 14.5807 14.5807C14.3007 14.8602 13.9643 15 13.5714 15H12.1429ZM6.42857 15C6.03571 15 5.69952 14.8602 5.42 14.5807C5.14 14.3007 5 13.9643 5 13.5714V6.42857C5 6.03571 5.14 5.69952 5.42 5.42C5.69952 5.14 6.03571 5 6.42857 5H7.85714C8.25 5 8.58643 5.14 8.86643 5.42C9.14595 5.69952 9.28571 6.03571 9.28571 6.42857V13.5714C9.28571 13.9643 9.14595 14.3007 8.86643 14.5807C8.58643 14.8602 8.25 15 7.85714 15H6.42857Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"}/>
                        :
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.97168 11.3912C0.974962 11.5817 1.03039 11.7676 1.13194 11.9288C1.23349 12.09 1.37728 12.2203 1.54768 12.3055C1.71784 12.4017 1.90995 12.4522 2.10539 12.4522C2.30083 12.4522 2.49295 12.4017 2.66311 12.3055L10.5717 7.41408C10.7438 7.33118 10.8889 7.2014 10.9906 7.03966C11.0922 6.87793 11.1461 6.6908 11.1461 6.49979C11.1461 6.30879 11.0922 6.12166 10.9906 5.95992C10.8889 5.79819 10.7438 5.66841 10.5717 5.58551L2.66311 0.730651C2.49295 0.634508 2.30083 0.583984 2.10539 0.583984C1.90995 0.583984 1.71784 0.634508 1.54768 0.730651C1.37728 0.815852 1.23349 0.946127 1.13194 1.10732C1.03039 1.26851 0.974962 1.45445 0.97168 1.64494V11.3912Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"} />
                        </svg>
                    }
                
            </span>
        )
    } else if(parentComponent == 'songVersion'){
        if(playIndex && playList && playList.length > 0 && playList[playIndex-1].version && version && playList[playIndex-1].version == version.song_version_id){
            return (
                <span onClick={() => playPauseFromVersion()} className="cp">
                    
                    
                        {
                            isPlaying ? 
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.1429 15C11.75 15 11.4138 14.8602 11.1343 14.5807C10.8543 14.3007 10.7143 13.9643 10.7143 13.5714V6.42857C10.7143 6.03571 10.8543 5.69952 11.1343 5.42C11.4138 5.14 11.75 5 12.1429 5H13.5714C13.9643 5 14.3007 5.14 14.5807 5.42C14.8602 5.69952 15 6.03571 15 6.42857V13.5714C15 13.9643 14.8602 14.3007 14.5807 14.5807C14.3007 14.8602 13.9643 15 13.5714 15H12.1429ZM6.42857 15C6.03571 15 5.69952 14.8602 5.42 14.5807C5.14 14.3007 5 13.9643 5 13.5714V6.42857C5 6.03571 5.14 5.69952 5.42 5.42C5.69952 5.14 6.03571 5 6.42857 5H7.85714C8.25 5 8.58643 5.14 8.86643 5.42C9.14595 5.69952 9.28571 6.03571 9.28571 6.42857V13.5714C9.28571 13.9643 9.14595 14.3007 8.86643 14.5807C8.58643 14.8602 8.25 15 7.85714 15H6.42857Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"}/>
                            </svg>
                            :
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.97168 11.3912C0.974962 11.5817 1.03039 11.7676 1.13194 11.9288C1.23349 12.09 1.37728 12.2203 1.54768 12.3055C1.71784 12.4017 1.90995 12.4522 2.10539 12.4522C2.30083 12.4522 2.49295 12.4017 2.66311 12.3055L10.5717 7.41408C10.7438 7.33118 10.8889 7.2014 10.9906 7.03966C11.0922 6.87793 11.1461 6.6908 11.1461 6.49979C11.1461 6.30879 11.0922 6.12166 10.9906 5.95992C10.8889 5.79819 10.7438 5.66841 10.5717 5.58551L2.66311 0.730651C2.49295 0.634508 2.30083 0.583984 2.10539 0.583984C1.90995 0.583984 1.71784 0.634508 1.54768 0.730651C1.37728 0.815852 1.23349 0.946127 1.13194 1.10732C1.03039 1.26851 0.974962 1.45445 0.97168 1.64494V11.3912Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"} />
                            </svg>
                            
                        }
                        
                    
                </span>
            )
        } else {
            return (
                <span onClick={() => watchPlayPauseFromVersion()} className="cp">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.97168 11.3912C0.974962 11.5817 1.03039 11.7676 1.13194 11.9288C1.23349 12.09 1.37728 12.2203 1.54768 12.3055C1.71784 12.4017 1.90995 12.4522 2.10539 12.4522C2.30083 12.4522 2.49295 12.4017 2.66311 12.3055L10.5717 7.41408C10.7438 7.33118 10.8889 7.2014 10.9906 7.03966C11.0922 6.87793 11.1461 6.6908 11.1461 6.49979C11.1461 6.30879 11.0922 6.12166 10.9906 5.95992C10.8889 5.79819 10.7438 5.66841 10.5717 5.58551L2.66311 0.730651C2.49295 0.634508 2.30083 0.583984 2.10539 0.583984C1.90995 0.583984 1.71784 0.634508 1.54768 0.730651C1.37728 0.815852 1.23349 0.946127 1.13194 1.10732C1.03039 1.26851 0.974962 1.45445 0.97168 1.64494V11.3912Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"}/>
                    </svg>
                </span>
            )
        }
    }    
   
}
function mapStateToProps(state) {
    return {
        playerSong: state.song.playerSong,
        playList: state.playlist.playList,
        playerState: state.playlist.playerState,
        isPlaying: state.isplaying.isPlaying,
        playIndex: state.playindex.playIndex,
        player: state.player.audioInstance,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        setPlayerSong: (song) => dispatch(songActions.setPlayerSong(song)),
        addSongVersionToPlayList: (song) => dispatch(playlistActions.addSongVersionToPlayList(song)),
        setPlayerState: (playerState)=> dispatch(playlistActions.setPlayerState(playerState)),
        setPlayIndex: ( playIndex ) => dispatch(playindexActions.setPlayIndex(playIndex)),
        setIsPlaying: ( isPlaying ) => dispatch(isplayingActions.setIsPlaying(isPlaying) ),
        listenSong: (song) => dispatch(songActions.listenSong(song))
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PlayPauseButton);
  