import * as types from './type';

import { Billing }  from  '../../services/billing';

export function getBilling(data) {
    return {
      type: types.GET_BILLING_HISTORY,
      payload: Billing.getBilling(data),
    };
};


export function prepareInvoice(data) {
  return {
    type: types.PREPARE_BILLING_INVOICE,
    payload: Billing.prepareInvoice(data),
  };
};

export function downloadBillingEntry(data){
  return {
    type: types.PREPARE_BILLING_ENTRY,
    payload: Billing.downloadBillingEntry(data),
  };
}