import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Curated = {
    getHomeCuratedLists,
    getCuratedAlbums,
    getCuratedSongs,
    getGenreCuratedAlbums,
    getSuggestedList
}

function getHomeCuratedLists(){
    const url = 'curated/get-mp3index-items.json?store_id=1&lang_code=en';
    return axiosReqGet()
            .get(url)
            .then(res => {
            return res; 
        })
        .catch(err => { 
            alert(err.response.data.message)      
            return err; 
        })
}
    
function getCuratedAlbums(data){
    
    const url = 'curated/index.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getCuratedSongs(data){
    
    const url = 'curated/get-songs.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getGenreCuratedAlbums(data){
    
    const url = 'curated/get-genre-item.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function getSuggestedList(){
    
    const url = 'curated/get-suggested-list.json?store_id=1&lang_code=en';
    return axiosReqGet()
            .get(url)
            .then(res => {
            return res; 
        })
        .catch(err => { 
            alert(err.response.data.message)      
            return err; 
        })
}



export default Curated;