import {axiosReqGet, axiosReqOther} from "./config";

export const Product = {
    getProducts
}

function getProducts(){
    const url = 'common/shopify-products.json?store_id=1&lang_code=en';
    return axiosReqGet()
            .get(url)
            .then(res => {
            return res; 
        })
        .catch(err => { 
            console.log('final err',err.response.data.message)
            return err; 
        })
}
    

export default Product;