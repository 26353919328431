import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import SingleDownloadButton from '../../elements/songElements/singleDownloadButton';
import DownloadAllButton from '../../elements/songElements/downloadAllButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton'; 
import DropboxButton from '../../elements/songElements/dropboxButton';
import TrendingTrackLoader from '../loader/trendingTrackLoader';
import {connect} from 'react-redux';
import * as actions from '../../store/trending/actions';


const  Top30TrendingTracks = ({ getTrendingSongs, currentTrending, activeBtn }) => {

    const [ activeSongId, setActiveSongId ] = useState(0);

	useEffect(() => { 
		getTrendingSongs();
	},[])

	

    return (
        <div>
            <div className="top">
                <h2>
                    {/* TRENDING TRACKS */}
                    <Link to={`/trending-tracks/current`} style={{ color: '#fff' }}>TRENDING TRACKS</Link>
                </h2>
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.02849 0.605551C5.95939 0.564275 5.8804 0.54248 5.79991 0.54248C5.71943 0.54248 5.64044 0.564275 5.57134 0.605551C5.51435 0.65071 5.47589 0.715201 5.46325 0.786812C5.45061 0.858423 5.46468 0.932183 5.50277 0.994122C6.90849 3.42841 7.20563 6.74269 5.28563 8.57127C4.54692 7.95049 3.96045 7.16853 3.57134 6.28555C2.86132 6.67989 2.27289 7.26121 1.86993 7.96638C1.46698 8.67155 1.2649 9.47363 1.28563 10.2856C1.31461 11.0013 1.48647 11.704 1.79108 12.3524C2.0957 13.0007 2.52691 13.5816 3.05936 14.0608C3.59181 14.54 4.21473 14.9078 4.89146 15.1427C5.5682 15.3776 6.28508 15.4747 6.99991 15.4284C10.6799 15.4284 12.5885 13.1427 12.7142 10.2856C12.8628 6.85698 10.4285 2.63984 6.02849 0.605551Z" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.857 10.2856C9.857 10.8919 9.61619 11.4732 9.18753 11.9019C8.75888 12.3305 8.1775 12.5714 7.57129 12.5714" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

            </div>
            <div className="track-list">
            {   
				currentTrending && currentTrending.length > 0 ?
                <ul>
                    {
                        currentTrending.map((song, index) => {
                            return <li key={`top30Trendingside${index}`} className={activeSongId == song.entity_id ? 'active' : ''} >
                                <div className="song-counter">
                                    <span className="song-number">{index+1}</span>
                                    <span className="play-pause" onClick={() => setActiveSongId(song.entity_id)}><PlayPauseButton parentComponent="songVersion" song={song} version={ song.version.song_entity_version_id ? song.version.song_entity_version_id : song.version[0]} /></span>
                                </div>
                                <div className="content">
                                    <div>
                                        <img src={song.image} alt={`dangerouslySetInnerHTML={{__html: song.title}}`} title=""/>
                                        <div className="track-name">
                                            <div className="text-2">
                                                <Link to={`/trending-tracks/current`} className="text-2"><span dangerouslySetInnerHTML={{__html: song.title}}></span></Link>
                                            </div>
                                            <div className="artist-name">
                                            { 
                                                song.artist_search && song.artist_search && song.artist_search.length > 0 ? 
                                                    song.artist_search.map((as, asIndex) => {
                                                        return <Link key={`as${asIndex}-${song.entity_id}`} to={`/mp3/artist/${as}`} className="text-5"><span dangerouslySetInnerHTML={{__html: as.trim()}}></span>{ asIndex != (song.artist_search.length -1) ? ',' : ''}&nbsp;</Link>
                                                    })
                                                :
                                                <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                            }
                                            </div>
                                            {/* <div className="text-5" dangerouslySetInnerHTML={{__html: song.artist}}></div> */}
                                        </div>
                                    </div>
                                    { activeBtn == 'download' ? <DownloadAllButton entity_id={song.entity_id}   /> : ''}
                                    { activeBtn == 'dropbox' ? <DropboxButton songId={song.entity_id} version={song.version[0] ? song.version[0] : ''} songSectionId={song.song_section_id} /> : ''}
                                </div>
                            </li>
                        })
                    }
				</ul>
                : <TrendingTrackLoader itemCount={30} />
            }
            </div>
		</div>		
    );
  
}


function mapStateToProps(state) {
	return {
        currentTrending: state.trending.currentTrending,
        activeBtn: state.activeDownloadBtn.activeDownloadBtn
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		getTrendingSongs: () => dispatch(actions.getLatestTrendingSong()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Top30TrendingTracks);
