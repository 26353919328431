import * as types from './type';

const initialState = { 
    products: '' 
}


export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PRODUCTS:
            if(action.payload){
                if(action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.products){
                    return {
                        ...state,
                        products: action.payload.data.data.products
                    };
                }
            } else {
                return {
                ...state
                }
            }
        default:
            
            return state
    }
}