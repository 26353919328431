import React, { useState, useEffect } from "react";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import MonthlyTopSongs from '../../components/monthlyTopSongs/monthlyTopSongs';

import HomeGenresSong from '../../components/homeGenresSong/homeGenresSong';
import HomeNewRelease from '../../components/homeNewRelease/homeNewRelease';
import StoreProducts from '../../components/storeProducts/storeProducts';

import RightSide from "../../components/rightSide/rightSide";


import MusicDashboard from '../../layouts/musicDashboard';

import { connect } from 'react-redux';

import * as genreActions from '../../store/genres/actions';
import * as actions from '../../store/song/actions';
import * as downloadAction from '../../store/download/actions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import Announcement from "../../components/announcement/announcement";


const  Home = ({ getHomeGenres, homeGenreData, getCustomerCrates, addSongToFavourite, displayLoading, hideLoading, getMostDownloadedToday }) => {

	
	useEffect(() => { 
        loadData();
	},[]);
	
	
	const loadData = async() =>{
		// Get genres filter to display on homepage
		displayLoading();
		await getHomeGenres();
		await getCustomerCrates();
		await addSongToFavourite();
		await getMostDownloadedToday();
		hideLoading();
		window.scrollTo({top: 0});
	}  

	return (
			<MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true, mostDownloaded: true}} />}>
				<div className="content-sec">
					<Announcement page_name={'mp3_home'} />
					<CuratedSpotlightCarousel title={'Curated Spotlights'} showItemCount={4} hideSeeAll={true} />
					<HomeGenresSong  /> 
					<HomeNewRelease  /> 
					<MonthlyTopSongs />
					<StoreProducts />
				</div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
	return {
		loggingIn: state.authentication.loggedIn
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		getHomeGenres: () => dispatch(genreActions.getHomeGenres()),
		getCustomerCrates: () => dispatch(actions.getCustomerCrates()),
		addSongToFavourite: () => dispatch(actions.addSongToFavourite()),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
		getMostDownloadedToday: () => dispatch(downloadAction.getMostDownloadedToday())
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(Home);
