import * as types from './type';

import { Curated }  from  '../../services/curated';

export function getHomeCuratedLists() {
    return {
      type: types.GET_HOME_CURATED_LIST,
      payload: Curated.getHomeCuratedLists(),
    };
};


export function getCuratedAlbums(data) {
  return {
    type: types.GET_CURATED_ALBUMS,
    payload: Curated.getCuratedAlbums(data),
  };
};


export function getCuratedSongs(data) {
  return {
    type: types.GET_CURATED_SONGS,
    payload: Curated.getCuratedSongs(data),
  };
};


export function getGenreCuratedAlbums(data) {
  return {
    type: types.GET_GENRE_CURATED_ALBUMS,
    payload: Curated.getGenreCuratedAlbums(data),
  };
};

export function getSuggestedList() {
  return {
    type: types.GET_SUGGESTED_CURATED_ALBUMS,
    payload: Curated.getSuggestedList(),
  };
};