import * as types from './type';

import { User }  from  '../../services/user';

export function signIn(post) {
    return {
      type: types.LOGIN_REQUEST,
      payload: User.signIn(post),
    };
};

export function logout(){
  return {
    type: types.LOGOUT,
    payload: User.logout(),
  };
}