import * as types from './type';

import { Top20 }  from  '../../services/top20';


export function getTop20Albums(data) {
  return {
    type: types.GET_TOP20_ALBUMS,
    payload: Top20.getTop20Albums(data),
  };
};


export function getTop20Songs(data) {
  return {
    type: types.GET_TOP20_SONGS,
    payload: Top20.getTop20Songs(data),
  };
};

export function getTop20AlbumsForMainPage(data) {
  return {
    type: types.GET_TOP20_ALBUMS_FOR_MAIN_PAGE,
    payload: Top20.getTop20Albums(data),
  };
};