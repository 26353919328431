import React, { useEffect, useState} from "react";

//custom components
import Table from 'react-bootstrap/Table';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import MusicDashboard from '../../layouts/musicDashboard';
import {connect} from 'react-redux';
import * as actions from '../../store/stats/actions';
import * as versionAction from '../../store/version/actions'
import { useNavigate, useParams } from "react-router-dom";

const  Stats = ({ getSearchSongStats, stats, displayLoading, hideLoading,  versions, getVersions }) => {

    const navigate = useNavigate();
    const [ processing, setProcessing] = useState(false);
    const [ currentFilterType,  setCurrentFilterType] = useState('keyword');
    const [ selectedVersion, setSelectedVersion ] = useState('0');

    useEffect(() => { 
        loadData();
    },[]);

    const  loadData = async () => {
        setProcessing(true);
        displayLoading();
        await getSearchSongStats({token: localStorage.user }).then((res) => {
            
        });
        // await getVersions();
        setProcessing(false);
        hideLoading();
        window.scrollTo({top: 0});
        
    }
    
    const filterStatsByType = (filterType) => {
        setCurrentFilterType(filterType)
        setProcessing(true);
        displayLoading();
        getSearchSongStats({token: localStorage.user, type: filterType }).then((res) => {
            
        });
        setProcessing(false);
        hideLoading();
        window.scrollTo({top: 0});
    }

    const filterStatsByVersion = (versionId) => {
        setCurrentFilterType('version');
        setSelectedVersion(versionId);
        setProcessing(true);
        displayLoading();
        getSearchSongStats({token: localStorage.user, type: 'version' }).then((res) => {
            
        });
        setProcessing(false);
        hideLoading();
        window.scrollTo({top: 0});
    }
    
  return (
    <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec"}>
        <div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Setting</h2>
                </div>
                <AccountNav/>
            </div> 
            <div className="play-list-sec">
                <div className="top-heading">
                
                    <div className="back-arrow-head">
                            <span className="cp" onClick={() => {
                                navigate(-1)
                            }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                            </span>
                            <h2>STATS (PREVIOUS WEEK)</h2>
                        </div>
                   
                </div>
                <div className="inner-block" style={{marginBottom: '20px'}}>
                    <div className="cardMark mb-3 d-flex justify-content-between align-items-center" style={{paddingLeft: '18px', paddingTop: '18px'}}><span>Filter Stats By</span></div>
                    <div className="top-list">
                        <ul>
                            <li className={currentFilterType === 'keyword' ? 'active cp' : 'cp' } onClick={() => filterStatsByType('keyword')}>{ currentFilterType === 'keyword' && processing ? <span className="text-3"><Spinner animation="border" variant="dark" size="sm" /></span> : <span>Keyword</span>}</li>
                            <li className={currentFilterType === 'artist' ? 'active cp' : 'cp' } onClick={() => filterStatsByType('artist')}>{ currentFilterType === 'artist' && processing ? <span className="text-3"><Spinner animation="border" variant="dark" size="sm" /></span> : <span>Artist</span>}</li>
                            <li className={currentFilterType === 'song' ? 'active cp' : 'cp' } onClick={() => filterStatsByType('song')}>{ currentFilterType === 'song' && processing ? <span className="text-3"><Spinner animation="border" variant="dark" size="sm" /></span> : <span>Song</span>}</li>        
                            <li className={currentFilterType === 'version' ? 'active cp' : 'cp' } onClick={() => filterStatsByType('version')}>{ currentFilterType === 'version' && processing ? <span className="text-3"><Spinner animation="border" variant="dark" size="sm" /></span> : <span>Version</span>}</li>        
                            <li className={currentFilterType === 'genres' ? 'active cp' : 'cp' } onClick={() => filterStatsByType('genres')}>{ currentFilterType === 'genres' && processing ? <span className="text-3"><Spinner animation="border" variant="dark" size="sm" /></span> : <span>Genres</span>}</li>        
                        </ul>
                    </div>
                </div>
                
                <div className="inner-block">
                    
                    {/* <div className="cardMark mb-3 d-flex justify-content-between align-items-center" style={{paddingLeft: '18px'}}><span>Filter By Version</span></div>
                    <div className="top-list">
                    { 
                        versions && versions.length > 0 ?
                            <ul className="">
                                {
                                versions.map((version, index)=>{
                                    return <li key={`version-filter-${version.version_id}`} className={selectedVersion === version.version_id ? 'active cp' : 'cp' }><span  onClick={() => filterStatsByVersion(version.version_id)}>{version.name}</span></li>
                                })
                                }
                            </ul>
                        : ''
                    }
                    </div> */}
                    <div className="table-content">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                <th>{ stats && stats.title ? stats.title : 'Stats'}</th>
                                <th>Search Count</th>
                                </tr>
                            </thead>
                            {
                                stats && stats.data && stats.data.length > 0 ?
                                <tbody>
                                    {
                                        stats.data.map((record, index)=>{
                                            return <tr key={`songReport${index}`}>
                                                    <td>{record.keyword}</td>
                                                    <td>{record.totalKeywordSearch}</td>
                                                   
                                            </tr>
                                        })
                                    }	
                                    
                                </tbody>
                                : ''
                            }
                            {
                                stats && stats.length == 0 ?
                                    <tbody><tr><td>You have no stats available  for {currentFilterType}.</td></tr></tbody>
                                :
                                ''
                            }
                        </Table>
                    </div>
                    
                
                    
                </div>
            </div>
            
        </div>
    </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        stats: state.stats.stats,
        versions: state.version.versions
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getSearchSongStats: (data) => dispatch(actions.getSearchSongStats(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getVersions: () => dispatch(versionAction.getVersions())
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Stats);