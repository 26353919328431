import * as types from './type';

const initialState = {
  songlists: []
};



export default function filecontainerReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_CONTAINER_SONG: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          songlists: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}