import * as types from './type';

import { Stats }  from  '../../services/stats';

export function getSearchSongStats(data) {
    return {
      type: types.GET_SEARCH_SONG_STATS,
      payload: Stats.getSearchSongStats(data),
    };
};
