import * as types from './type';

import { Version }  from  '../../services/version';

export function getVersions(data) {
    return {
      type: types.GET_VERSIONS,
      payload: Version.getVersions(data),
    };
};

export function getEditSongVersions(data){
  return {
    type: types.GET_EDIT_SONG_VERSIONS,
    payload: Version.getEditSongVersions(data),
  };
}

export function removeSongVersion(data){
  return {
    type: types.REMOVE_SONG_VERSIONS,
    payload: Version.removeSongVersion(data),
  };
}