import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';
const USER_TOKEN = store.getState().authentication.user;

export const Stats = {
    getSearchSongStats
}

function getSearchSongStats(data){
    const url = 'stats/get-search-stats.json?store_id=1&lang_code=en';
   
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

export default Stats;