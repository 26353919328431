import * as types from './type';

const initialState = {
  stats: []
};



export default function statsReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_SEARCH_SONG_STATS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          stats: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    default: {
      return {
        ...state
      };
    }
    
  }

}