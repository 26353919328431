import {combineReducers} from 'redux';

import curated from './curated/reducers'
import song from './song/reducers'
import mtk from './mtk/reducers'
import genre from './genres/reducers'
import playlist from './playlist/reducers'
import playindex from './playindex/reducers'
import isplaying from './isplaying/reducers'
import player from './player/reducers'
import authentication from './authentication/reducers'
import customer from './customer/reducers'
import download from './download/reducers'
import product from './product/reducers'
import trending from './trending/reducers'
// import mobilemenu from './mobilemenu/reducers';
import common from './common/reducers';
import crate from './crate/reducers';
import billing from './billing/reducers';
import faq from './faq/reducers';
import helpdesk from './helpdesk/reducers';
import subscription from './subscription/reducers';
import coupon from './coupon/reducers';
import uploads from './uploads/reducers';
import version from './version/reducers';
import top20 from './top20/reducers';
import mixes from './mixes/reducers';
import career from './career/reducers';
import filecontainer from './filecontainer/reducers'
import {loadingBarReducer} from 'react-redux-loading-bar';
import theme from './theme/reducers';
import dropbox from './dropbox/reducers';
import activeDownloadBtn from './activeDownloadBtn/reducers';
import stats from './stats/reducers';

const appReducer = combineReducers({
  curated,
  song,
  genre,
  playlist,
  playindex,
  isplaying,
  player,
  authentication,
  customer,
  download,
  product,
  trending,
  // mobilemenu,
  loadingBar: loadingBarReducer,
  common,
  crate,
  billing,
  faq,
  helpdesk,
  subscription,
  coupon,
  uploads,
  version,
  filecontainer,
  top20,
  theme,
  mixes,
  career,
  dropbox,
  activeDownloadBtn,
  mtk,
  stats
});

const rootReducer = (state, action) => {
  if (action.type == 'USER_LOGOUT') {
    return appReducer(state = 'undefined', action);
  }
  return appReducer(state, action);
};

export default rootReducer;