import * as types from './type';

const initialState = {
  recentTickets: [],
  departments: '',
  priorities: '',
  tickets: '',
  ticketDetail: '',
  attachments: '',
  submitSuccess: ''
};



export default function helpdeskReducer(state = initialState, action) {
  switch (action.type) {
      
    case types.GET_RECENT_TICKETS: {
      
      if (action.payload && action.payload.data) {
          return {
          ...state,
          recentTickets: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_TICKETS: {
      
      if (action.payload && action.payload.data) {
          return {
          ...state,
          tickets: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_SUPPORT_PRIORITIES: {
      
      if (action.payload && action.payload.data) {
          return {
          ...state,
          priorities: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_SUPPORT_DEPARTMENTS: {
      if (action.payload && action.payload.data) {
          return {
          ...state,
          departments: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.SUBMIT_NEW_TICKET: {
      if (action.payload && action.payload.data) {
          return {
          ...state,
          submitSuccess: action.payload.data.tickets
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.VIEW_TICKET:{
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          ticketDetail: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.SUBMIT_REPLY:{
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          ticketDetail: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.UPLOAD_ATTACHMENTS:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          attachments: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    default: {
      return {
        ...state
      };
    }
    
  }

}