import * as types from './type';

const initialState = {
  versions: [],
  editSongVersions: '',
  removeSongVersion: ''
};



export default function versionReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_VERSIONS: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          versions: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_EDIT_SONG_VERSIONS: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          editSongVersions: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.REMOVE_SONG_VERSIONS: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          removeSongVersion: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}