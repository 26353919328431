import React, { useState, useEffect } from "react";

import FavouriteButton from '../../elements/songElements/favouriteButton';
import SingleDownloadButton from '../../elements/songElements/singleDownloadButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import DropboxButton from '../../elements/songElements/dropboxButton';
import PlaylistButton from '../../elements/songElements/playlistButton';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Popup from 'reactjs-popup';
import Form from 'react-bootstrap/Form';
import config from '../../config/config';
import  API_BASE_URL  from '../../config/config';
import { FilePond, File, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import {connect} from 'react-redux';
import * as actions from '../../store/customer/actions';

const  DownloadSongTrack = ({ songs,  activeBtn, userProfile, playlistAlbums, authentication, displayLoading, hideLoading, addPlaylistAlbum, getCustomerPlayLists, getCustomerPlayListCoverImage, addSongToPlaylist }) => {

    const [files, setFiles] = useState([]);
	const [activeSongId, setActiveSongId] = useState(0);
    const [activeAlbumId, setActiveAlbumId] = useState(0);
    const [ processing, setProcessing ] = useState(false);
    const [createNewPlayList, setCreateNewPlayList ] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        cover_image: ''
    });
    const [validated, setValidated] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ submit, setSubmit ] = useState(false);

    const uploadURL = API_BASE_URL.apiUrl+"/customer/upload-customer-album-image.json?token="+authentication.user+"&store_id=1&lang_code=en";

    useEffect(() => { 
        getCustomerPlayLists({});
    },[]);

    console.log( localStorage.getItem('user'))

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
    }

    const findFormErrors = () => {
        const { title, cover_image, song_entity_id } = formData
        const newErrors = {}

        
        // password errors
        if ( !cover_image || cover_image === '' ) newErrors.cover_image = 'Please upload cover image'
        if ( !title || title === '' ) newErrors.title = 'Playlist Name cannot be blank!'
        return newErrors
    }

    const handleSubmit = (event) => { 
        
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            displayLoading();
            setSubmit(true);
            addPlaylistAlbum(formData).then(res => {
                if(res && res.payload && res.payload.data){
                    setSubmit(false);
                    getCustomerPlayLists();
                    setCreateNewPlayList(false);
                }
            }); 
            hideLoading();
        }
    }

    const onOpenPopUp = (songId) => {
        setFormData({
            ...formData,
            song_entity_id: songId
        }); 
        setActiveSongId(songId);
        
    }
    const onClosePopUp = () => {
        setFormData({
            ...formData,
            song_entity_id: ''
        }); 
        setActiveSongId(0);
    }

    const getUploadedFile = (file) => {
        getCustomerPlayListCoverImage().then(res => {
            if(res && res.payload && res.payload.data){
                
                setFormData({
                    ...formData,
                    cover_image : res.payload.data.cover_image
                });  
            }
        });
    }

    const addSongToAlbum = (albumId) => {
        displayLoading();
        setProcessing(true);
        setActiveAlbumId(albumId);
        if(activeSongId > 0){
            let postData = {
                song_entity_id: activeSongId,
                customer_playlist_album_id: albumId
            }
            addSongToPlaylist(postData).then(res => {
                hideLoading();
                setProcessing(false);
            });
        }
    }
    
    return (
        <div className="play-list">
        {
            songs && songs.length > 0 ? 
                songs.map((song, index)=>{
                    return <div className={ activeSongId == song.entity_id ? "block" : "block"} key={`songcrate${song.entity_id}${song.version.song_version_id}${index}`}>
                                <div className="play-track">
                                    {/* <PlayPauseButton parentComponent="songTrack" song={song} /> */}
                                    <PlayPauseButton parentComponent="songVersion" song={song} version={song.version} />
                                    <div className="track-name">
                                        <span className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                        { 
                                            song.artist_search && song.artist_search[0].artist && song.artist_search[0].artist.length > 0 ? 
                                                song.artist_search[0].artist.map((as, asIndex) => {
                                                    return <div key={`as${asIndex}-${song.entity_id}`} ><Link to={`/mp3/artist/${as}`} dangerouslySetInnerHTML={{__html: as}} className="text-5"></Link> <span className="text-5">&nbsp;{ asIndex < (song.artist_search[0].artist.length -1) ? song.artist_search[0].split_string : ''}&nbsp;</span></div>
                                                })
                                            :
                                            <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                        }
                                        {/* <span className="text-5" dangerouslySetInnerHTML={{__html: song.artist}}></span> */}
                                    </div>
                                </div>
                                <div className="play-track-content">
                                    <div>
                                        <span className="text-5">{song.version.version_label}</span>
                                        <span className="text-5">{song.max_bpm}</span>
                                        {/* <span className="text-4">{song.song_key}</span> */}
                                        <span className="text-5">{song.created_at}</span>
                                        {/* <span className="text-4">{song.version.version_label}</span> */}
                                    </div>
                                    
                                    <div className="divider">
                                        <FavouriteButton song={song} />
                                        { activeBtn == 'download' ? <SingleDownloadButton songId={song.entity_id} version={song.version} songSectionId={1} /> : ''}
                                        { activeBtn == 'dropbox' ? <DropboxButton songId={song.entity_id} version={song.version} /> : '' }
                                        {/* <PlaylistButton songId={song.entity_id} version={song.version} /> */}
                                        
                                    </div>
                                    
                                </div>
                                
                        </div>
                    })
            : ''
        }
        </div>
    );
  
}


function mapStateToProps(state) {
  return {
    activeBtn: state.activeDownloadBtn.activeDownloadBtn,
    userProfile: state.customer.customerProfile,
    playlistAlbums: state.customer.playlistAlbums,
    authentication: state.authentication,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addPlaylistAlbum: (post) => dispatch(actions.addPlaylistAlbum(post)),
    getCustomerPlayLists: (data) => dispatch(actions.getCustomerPlayLists(data)),
    addSongToPlaylist: (post) => dispatch(actions.addSongToPlaylist(post)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getCustomerPlayListCoverImage: () => dispatch(actions.getCustomerPlayListCoverImage()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(DownloadSongTrack);
