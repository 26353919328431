import {axiosReqGet, axiosReqOther, axiosReqOtherAttachment} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Helpdesk = {
    getRecentTickets,
    getPriorities,
    getDepartments,
    submitTicket,
    getTickets,
    getTicket,
    submitReply,
    uploadAttachments
}

function getRecentTickets(){
    
    const url = 'helpdesk/get-recent-tickets.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    };
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getTickets(data){
    
    const url = 'helpdesk/tickets.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getTicket(data){
    
    const url = 'helpdesk/get-ticket.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function submitReply(data){
    
    const url = 'helpdesk/reply.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function uploadAttachments(data){
    
    const url = 'helpdesk/upload-attachments.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOtherAttachment(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getPriorities(){
    
    const url = 'helpdesk/helpdesk-priorities.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    };
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getDepartments(){
    
    const url = 'helpdesk/helpdesk-departments.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    };
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}


function submitTicket(data){
    
    const url = 'helpdesk/submit-ticket.json?store_id=1&lang_code=en';
    data.token =  USER_TOKEN;

    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

export default Helpdesk