import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link }  from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from '../../components/loader/imageSliderLoader';

import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';


const  Carousel = ({ items, itemLinkPrefix, primaryKeyName, title, hideSeeAll, seeAllLink, titleKey, imageKey }) => {

	// console.log('items', items)
	useEffect(() => { 
		// getCuratedItems();
	},[])

	const settings = {
		infinite: false,
		centerMode: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		responsive: [
		]
	};
    
    return (
      <div className="slider-sec">
			<div className="top-heading">
				<h2>{title}</h2>
				{hideSeeAll && hideSeeAll == true ? '' : <Link to={seeAllLink} className="text-2">View all</Link> }
			</div>
			{
				items && items.length > 0 ?
					<Slider {...settings}>
						{
							items.map((item, index) => {
								if(item){
									return <div key={`${title}-${index}`} className="item">
										{
											<div className="box">
												<Link to={`${itemLinkPrefix}${item[primaryKeyName]}`}><img src={item[imageKey]} alt={item[titleKey]} title={item[titleKey]} /></Link>
												<Link to={`${itemLinkPrefix}${item[primaryKeyName]}`}>	<h5>{item[titleKey]}</h5> </Link>
											</div>
										}
									</div>
								} else {
									return null;
								}
							})
						}
					</Slider>
				: 
				// <div style={{textAlign: 'center'}}><Spinner animation="border" variant="danger" size="lg" /></div>
				<ImageSliderLoader itemCount={8} />
			}
			
		</div>
				
				
    );
  
}


function mapStateToProps(state) {
	return {
		// curatedItems: state.curated.homeCuratedList
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
