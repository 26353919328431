import * as types from './type';

import { Helpdesk }  from  '../../services/helpdesk';

export function getRecentTickets(data) { 
    return {
      type: types.GET_RECENT_TICKETS,
      payload: Helpdesk.getRecentTickets(data),
    };
};

export function getTickets(data) { 
  return {
    type: types.GET_TICKETS,
    payload: Helpdesk.getTickets(data),
  };
};

export function getDepartments() { 
  return {
    type: types.GET_SUPPORT_DEPARTMENTS,
    payload: Helpdesk.getDepartments(),
  };
};


export function getPriorities() { 
  return {
    type: types.GET_SUPPORT_PRIORITIES,
    payload: Helpdesk.getPriorities(),
  };
};

export function submitTicket(post) { 
  return {
    type: types.SUBMIT_NEW_TICKET,
    payload: Helpdesk.submitTicket(post),
  };
};

export function getTicket(post) { 
  return {
    type: types.VIEW_TICKET,
    payload: Helpdesk.getTicket(post),
  };
};


export function submitReply(post) { 
  return {
    type: types.SUBMIT_REPLY,
    payload: Helpdesk.submitReply(post),
  };
};

export function uploadAttachments(post) { 
  return {
    type: types.UPLOAD_ATTACHMENTS,
    payload: Helpdesk.uploadAttachments(post),
  };
};