import * as types from './type';

const initialState = {
    top20Albums: '',
    top20Songs: '',
    albums: ''
};



export default function top20Reducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_TOP20_ALBUMS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          top20Albums: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_TOP20_ALBUMS_FOR_MAIN_PAGE: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          albums: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_TOP20_SONGS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          top20Songs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}