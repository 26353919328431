import * as types from './type';

const initialState = {
  faqs: [],
};



export default function faqReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_FAQS: {
      if (action.payload && action.payload.data) {
        
        return {
          ...state,
          faqs: action.payload.data.category[0]
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}