import { createStore, applyMiddleware } from "redux";
import promise from 'redux-promise';
import reducers from "./reducers";
import { loadingBarMiddleware } from 'react-redux-loading-bar'


// loadingBarMiddleware({
//     promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
// })
const middleware = applyMiddleware(promise, loadingBarMiddleware());

export const store =  createStore(reducers, middleware);