import * as types from './type';

const initialState = {
    songVersion: '',
    bundleVersion: [],
    mostDownloadedToday: '',
    downloadRescue: '',
    customerDownloads: ''
};



export default function downloadReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.DOWNLOAD_SONG_VERSION: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          songVersion: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.DOWNLOAD_SONG_BUNDLE: {
        if (action.payload && action.payload.data) {
          return {
            ...state,
            bundleVersion: action.payload.data
          }
        } else {
          return {
            ...state
          }
        }
    }
    
    case types.MOST_DOWNLOAD_SONG_TODAY: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          mostDownloadedToday: action.payload.data.songs
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.DOWNLOAD_HISTORY: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          downloadRescue: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.CUSTOMER_DOWNLOADS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerDownloads: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}