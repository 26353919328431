import * as types from './type';

const initialState = {
  billing: '',
  invoiceUrl: ''
};



export default function billingReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_BILLING_HISTORY: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          billing: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.PREPARE_BILLING_INVOICE: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          invoiceUrl: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.PREPARE_BILLING_ENTRY: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          invoiceUrl: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}